<template>
  <v-card>
    <v-card-title>Assign Multiple Projects to User</v-card-title>

    <v-card-text>
      <v-form ref="form">
        <v-autocomplete
          :items="users"
          item-text="name"
          item-value="userId"
          v-model="userIds"
          multiple
          chips
          deletable-chips
          autocomplete
          label="Users"
          :rules="multiRules"
        ></v-autocomplete>

        <v-autocomplete
          label="Projects"
          :items="projects"
          item-text="name"
          multiple
          chips
          deletable-chips
          item-value="_id"
          v-model="projectIds"
          :rules="multiRules"
        ></v-autocomplete>
      </v-form>
      <v-btn @click="assignProjects()" class="mt-5" color="primary">Assign Projects</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import RestResource from "../../services/dataServiceBuyer";
const service = new RestResource();
export default {
  data() {
    return {
      users: [],
      projects: [],
      projectIds: [],
      userIds: [],
      multiRules: [
        (value) => !!value || "Field is required.",
        (value) => (value || "").length > 0 || `Field is required`,
      ],
    };
  },

  mounted() {
    this.fetchUsers();
    this.loadProjects();
  },

  methods: {
    fetchUsers() {
      this.$setLoader();
      service.fetchUsers().then((r) => {
        this.users = r.data;
        this.$disableLoader();
      });
    },
    loadProjects() {
      service.fetchProjects().then((r) => {
        this.projects = r.data;
      });
    },

    assignProjects() {
      if (this.$refs.form.validate()) {
        let data = {
          projectId: this.projectIds,
          userId: this.userIds,
        };
        this.$setLoader();
        service.assignProjectsToUser(data).then((r) => {
          r;
          this.$disableLoader();
        });
      }
    },
  },
};
</script>